<template>
  <v-app id="product">
    <div class="text-right">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
    </div>
    <v-container>
      <v-row>
        <v-col cols="12"> </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-field text-left no-right no-bottom">
          Email Address:
          <span id="required">*</span>
        </v-col>
        <v-col cols="6" class="text-left no-left no-bottom no-top">
          <v-text-field
            outlined
            v-model="texFieldState.emailAddress"
            :rules="emailRules"
            autocomplete="off"
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-field text-left no-right no-bottom">
          Password:
          <span id="required">*</span>
        </v-col>
        <v-col
          cols="6"
          class="text-left no-left no-bottom no-top"
          v-if="!isEdit"
        >
          <v-text-field
            outlined
            v-model="texFieldState.password"
            :rules="passwordRules"
            autocomplete="off"
            dense
          />
        </v-col>
        <v-col cols="6" class="label-field text-left no-right no-bottom" v-else>
          <p>
            <v-hover v-slot:default="{ hover }">
              <a
                @click="sendEmail(item)"
                :class="hover ? 'anchor-tag-hov' : 'anchor-tag'"
                >Click Here</a
              >
            </v-hover>
            to send reset password
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-field text-left no-right no-bottom"
          >Group :
        </v-col>
        <v-col cols="6" class="text-left no-left no-bottom no-top">
          <v-select
            outlined
            v-model="texFieldState.group"
            :items="groupOptions"
            item-text="value"
            item-value="id"
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-field text-left no-right no-bottom">
          Number of Machines:
        </v-col>
        <v-col cols="1" class="text-left no-left no-bottom no-top">
          <v-text-field
            outlined
            v-model="texFieldState.number_machines"
            :rules="numberOfMAchineRule"
            autocomplete="off"
            dense
          />
        </v-col>
        <v-col cols="3"> (0 in use) </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-field text-left no-right no-bottom"
          >Bypass Security:
        </v-col>
        <v-col cols="1" class="text-left no-left no-bottom no-top">
          <v-checkbox v-model="byPassSecurity" :value="byPassSecurity" dense />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-field text-left no-right no-bottom"
          >First Name:
        </v-col>
        <v-col cols="6" class="text-left no-left no-bottom no-top">
          <v-text-field outlined v-model="texFieldState.firstName" dense />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-field text-left no-right no-bottom"
          >Last Name:
        </v-col>
        <v-col cols="6" class="text-left no-left no-bottom no-top">
          <v-text-field outlined v-model="texFieldState.lastName" dense />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-field text-left no-right no-bottom"
          >Company Name:
          <span id="required">*</span>
        </v-col>
        <v-col cols="6" class="text-left no-left no-bottom no-top">
          <v-text-field
            outlined
            v-model="texFieldState.companyName"
            :rules="companyNameRules"
            autocomplete="off"
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-field text-left no-right no-bottom"
          >Client Name (Agencies Only):
        </v-col>
        <v-col cols="6" class="text-left no-left no-bottom no-top">
          <v-text-field outlined v-model="texFieldState.clientName" dense />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-field text-left no-right no-bottom"
          >Street Address:
        </v-col>
        <v-col cols="6">
          <textarea
            id="hedlinetextarea"
            rows="5"
            cols="75"
            style="
              font-size: 12px;
              line-height: 20px;
              padding: 10px;
              font-family: inherit;
              border: 1px #7e7b7b solid;
              resize: both !important;
              border-radius: 4px;
              width: 100%;
              max-width: 135%;
            "
            v-model="texFieldState.streetAddress"
          >
          </textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-field text-left no-right no-bottom"
          >City:
        </v-col>
        <v-col cols="6" class="text-left no-left no-bottom no-top">
          <v-text-field outlined v-model="texFieldState.city" dense />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-field text-left no-right no-bottom"
          >State/Province:
        </v-col>
        <v-col cols="6" class="text-left no-left no-bottom no-top">
          <v-select
            outlined
            v-model="texFieldState.state"
            :items="stateProvinance"
            item-text="stateName"
            item-value="stateID"
            label="Select One"
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-field text-left no-right no-bottom"
          >Division:
        </v-col>
        <v-col cols="6" class="text-left no-left no-bottom no-top">
          <v-text-field outlined v-model="texFieldState.divsion" dense />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-field text-left no-right no-bottom"
          >Zip/Postal Code:
        </v-col>
        <v-col cols="6" class="text-left no-left no-bottom no-top">
          <v-text-field outlined v-model="texFieldState.zipCode" dense />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-field text-left no-right no-bottom"
          >Phone:
        </v-col>
        <v-col cols="6" class="text-left no-left no-bottom no-top">
          <v-text-field outlined v-model="texFieldState.phone" dense />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-field text-left no-right no-bottom"
          >Fax:
        </v-col>
        <v-col cols="6" class="text-left no-left no-bottom no-top">
          <v-text-field outlined v-model="texFieldState.fax" dense />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-margin"></v-col>
        <v-col cols="6">
          <fieldset>
            <legend><b>Country</b></legend>
            <v-radio-group v-model="country">
              <v-radio label="US Only" value="US"></v-radio>
              <v-radio label="Canada Only" value="CA"></v-radio>
              <v-radio label="US and Canada Both" value="BOTH"></v-radio>
            </v-radio-group>
          </fieldset>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-margin"></v-col>
        <v-col cols="6">
          <fieldset>
            <legend><b>Media Channels</b></legend>
            <span v-for="e in mediaChannel">
              <v-checkbox
                v-model="selMediaChannel"
                :label="e.mChannelName"
                :value="e.mChannelID"
                hide-details
              ></v-checkbox>
            </span>
          </fieldset>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-margin"></v-col>
        <v-col cols="6">
          <fieldset>
            <legend><b>Audience</b></legend>
            <span v-for="e in audience">
              <v-checkbox
                v-model="selAudience"
                :label="e.mPanelName"
                :value="e.mPanelID"
                hide-details
              ></v-checkbox>
            </span>
          </fieldset>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-margin">
          <!-- <div v-for="node in _selection">ssss {{ node }}</div> -->
        </v-col>
        <v-col cols="6">
          <fieldset style="max-height: 400px; overflow-y: scroll">
            <legend><b>Sector/Category/Sub Category</b></legend>
            <span v-if="sectorData.length > 0">
              <v-treeview
                selectable
                open-all
                return-object
                selected-color="#3f51b5"
                v-model="selectedSecor"
                :items="sectorData"
                :selection-type="selectionType"
              ></v-treeview>
            </span>

            <!-- <span v-for="(e, index) in 100">
              <v-checkbox
                v-model="setSectorCategorySubCategory"
                :label="e.toString()"
                :value="index"
                hide-details
              ></v-checkbox>
            </span> -->
          </fieldset>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-margin"></v-col>
        <v-col cols="6">
          <fieldset>
            <legend><b>Exclude from Power Search</b></legend>
            <span v-for="(e, key, index) in exclude_search" :key="index">
              <v-checkbox
                v-model="setExcludeFromPowerSearch"
                :label="e"
                :value="parseInt(key)"
                hide-details
              ></v-checkbox>
            </span>
          </fieldset>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-margin">Dashboard Permissions</v-col>
        <v-col cols="6">
          <v-select
            outlined
            v-model="selDashboardPermission"
            :items="dashboardPermission"
            class="select-chip"
            item-text="value"
            item-value="id"
            label="EDC / LDC / TDSP"
            chips
            dense
            multiple
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 2">
                <span>{{ item.value }}</span>
              </v-chip>
              <span
                v-if="index === 2"
                class="text-grey text-caption align-self-center"
              >
                (+{{ selDashboardPermission.length - 2 }} others)
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-margin"></v-col>
        <v-col cols="6">
          <fieldset>
            <legend><b>Additional Fields For Power Search</b></legend>
            <span v-for="(e, key, index) in additonalField" :key="index">
              <v-checkbox
                v-model="setAdditionalFieldsForPowerSearch"
                :label="extractAdditionalFieldName(e)"
                :value="parseInt(key)"
                hide-details
              ></v-checkbox>
            </span>
          </fieldset>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-margin"></v-col>
        <v-col cols="6">
          <fieldset>
            <legend><b>Page Permission</b></legend>
            <span v-for="e in pagePermission">
              <v-checkbox
                v-model="setPagePermission"
                :label="e.value"
                :value="e.id"
                hide-details
              ></v-checkbox>
            </span>
          </fieldset>
        </v-col>
      </v-row>
      <!-- aaa <v-checkbox label="dddd" value="dddd" hide-details></v-checkbox> -->
      <v-row>
        <v-col cols="4" class="label-margin"></v-col>
        <v-col cols="6">
          <fieldset>
            <legend><b>Anotation Tool Link Permission</b></legend>
            <span v-for="e in anotationToolLinkPermission">
              <v-checkbox
                v-model="setAnotationToolLinkPermission"
                :label="e"
                :value="e"
                hide-details
              ></v-checkbox>
            </span>
          </fieldset>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-margin">Send Email: </v-col>
        <v-col cols="6">
          <v-radio-group :mandatory="false" row v-model="sendEmail">
            <v-radio label="None" :value="1"></v-radio>
            <v-radio label="Welcome" :value="2"></v-radio>
            <v-radio label="IP Limit" :value="3"></v-radio>
            <v-radio label="Company Usage Report" :value="4"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="label-margin"></v-col>
        <v-col cols="6">
          <v-row>
            <v-col cols="4">
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    no-title
                    v-model="sdate"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="sdate"
                  no-title
                  ref="datepicker"
                  event-color="white"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    no-title
                    v-model="edate"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="edate"
                  no-title
                  scrollable
                  ref="datepicker"
                  event-color="white"
                  outlined
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="isEdit">
        <v-col cols="4"></v-col>
        <v-col cols="6">
          <!-- Update for all A&D Mortgage users
     [Media Channels, Audience,
     Sector/Category/Sub Category, Power Search, Dashboard Permissions] -->
          <v-checkbox v-model="updateMediaChannelandOther" hide-details>
            <div slot="label">
              Update for all <b>{{ texFieldState.companyName }}</b> users [Media
              Channels, Audience, Sector/Category/Sub Category, Power Search,
              Dashboard Permissions]
            </div>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row v-if="isEdit">
        <v-col cols="4"></v-col>
        <v-col cols="6">
          <!-- Update for all A&D Mortgage users
     [Group, Number of Machines, Bypass Security] -->
          <v-checkbox v-model="updateByPassandOther" hide-details>
            <div slot="label">
              Update for all <b>{{ texFieldState.companyName }}</b> users
              [Group, Number of Machines, Bypass Security]
            </div>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4"> </v-col>
        <v-col cols="6" v-if="!isEdit">
          <v-btn
            small
            color="primary"
            class="mt-5"
            @click="save(texFieldState)"
          >
            Save
          </v-btn>
          <v-btn small color="primary" class="mt-5" @click="saveAndAddMore">
            Save & add more
          </v-btn>
        </v-col>
        <v-col cols="6" v-else>
          <v-btn small color="primary" class="mt-5" @click="editData">
            Update
          </v-btn>
          <v-btn
            small
            color="primary"
            class="mt-5"
            @click="$router.push('/manage-client-users')"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "MemberManagementAdEdit",
  components: {
    Loading,
  },
  data: () => ({
    singleSelect: false,
    mediaChannel: [],
    selMediaChannel: [],
    audience: [],
    pagePermission: [
      { id: 128, value: "Power Search" },
      { id: 127, value: "Trend Reports" },
      { id: 126, value: "Retrieval Services" },
      { id: 125, value: "Digital Dashboard" },
      { id: 124, value: "RPV Dashboard" },
    ],
    groupOptions: [
      { id: 0, value: "None" },
      { id: 2, value: "Competiscan Admin" },
      { id: 3, value: "Suntec Admin" },
      { id: 1, value: "Super Admin" },
    ],
    selAudience: [],
    anotationToolLinkPermission: ["Anotation Tool Link"],
    setAnotationToolLinkPermission: [],
    setPagePermission: [],
    setAdditionalFieldsForPowerSearch: [],
    setDashboardPermissions: [],
    setExcludeFromPowerSearch: [],
    setSectorCategorySubCategory: [],
    country: "BOTH",
    sendEmail: 1,
    sdate: moment().subtract(2, "days").format("YYYY-MM-DD"),
    edate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    byPassSecurity: false,
    isEdit: false,
    updateMediaChannelandOther: false,
    updateByPassandOther: false,
    texFieldState: {
      IPAddress: "",
      active: "",
      bypass: 0,
      city: "",
      clientName: "",
      companyName: "",
      country: "",
      dateAdded: "",
      emailAddress: "",
      ext: "",
      fax: "",
      firstName: "",
      insert_update_date: "",
      interestArea: "",
      ipnotify: null,
      is_public_user: null,
      lastName: "",
      loginType: "",
      number_machines: 1,
      // ordering: null,
      password: "PItu@RpEDY",
      phone: "",
      plevel: null,
      reset_password_token: null,
      state: null,
      streetAddress: "",
      // userID: null,
      zipCode: "",
      // group: 0,
      // divsion: "",
    },

    sector: [],
    selectedSecor: [],
    sectorData: [],
    allParentNodes: true,
    isLoading: false,
    fullPage: true,
    stateProvinance: [],
    selectionType: "all",
    selectionOption: ["leaf", "independent"],
    additonalField: [],
    dashboardPermission: [],
    exclude_search: [],
    selDashboardPermission: [],
    userId: null,
    permissionToUpdate: {},
    modified_by: "",
    emailRules: [
      (v) => !!v || "Please enter email address.",
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Please enter valid email address.",
    ],
    passwordRules: [
      (value) => !!value || "Please enter the password.",
      (value) =>
        (value &&
          value.length >= 15 &&
          /^(?=.*[A-Z])(?=.*\d)(?=.*\W)/.test(value)) ||
        "Password policy: Min. 15 characters with at least one capital letter, a number and a special character(e.g:uv$yrde34U4RX5f>)",
    ],
    numberOfMAchineRule: [
      (v) => (v && v > 0) || "Only Real number are allowed.",
    ],
    companyNameRules: [(value) => !!value || "Please enter Company Name."],
    // firstNameRule: [(value) => !!value || "Please enter first name."],
    // lastNameRule: [(value) => !!value || "Please enter last name."],
    // mediaChannelRules: [(value) => !!value || "Please select Media Channels."],
  }),
  watch: {
    selMediaChannel(newVal, oldVal) {
      let view = this;
      if (oldVal.length > 0 || !view.isEdit) {
        Object.assign(view.permissionToUpdate, {
          mediachannel: newVal,
        });
      }
    },
    selAudience(newVal, oldVal) {
      let view = this;
      if (oldVal.length > 0 || !view.isEdit) {
        Object.assign(view.permissionToUpdate, {
          audience: newVal,
        });
      }
    },
    byPassSecurity(o) {
      let view = this;
      if (o) {
        view.texFieldState.bypass = 1;
      } else {
        view.texFieldState.bypass = 0;
      }
    },
    selectedSecor(newVal, oldVal) {
      let view = this;
      let selIDs = [];
      console.log("SSs.", newVal, oldVal, view.isLoading);
      if (
        (oldVal.length > 0 && newVal.length !== oldVal.length) ||
        !view.isEdit
      ) {
        for (let i = 0; i < newVal.length; i++) {
          selIDs.push(newVal[i].id);
        }
        Object.assign(view.permissionToUpdate, {
          sector: selIDs,
        });
        console.log("SSs", view.permissionToUpdate);
      }
    },
    setExcludeFromPowerSearch(newVal, oldVal) {
      let view = this;
      if (oldVal.length > 0 || !view.isEdit) {
        Object.assign(view.permissionToUpdate, {
          additional_exclude: newVal,
        });
      }
    },
    selDashboardPermission(newVal, oldVal) {
      let view = this;
      if (oldVal.length > 0 || !view.isEdit) {
        Object.assign(view.permissionToUpdate, {
          edc_user: newVal,
        });
      }
    },
    setAdditionalFieldsForPowerSearch(newVal, oldVal) {
      let view = this;
      console.log("Saadd", view.isLoading);
      if (oldVal.length > 0 || !view.isEdit) {
        Object.assign(view.permissionToUpdate, {
          additional: newVal,
        });
      }
    },
    setPagePermission(newVal, oldVal) {
      let view = this;
      if (oldVal.length > 0 || !view.isEdit) {
        Object.assign(view.permissionToUpdate, {
          module: newVal,
        });
      }
    },
    // setAnotationToolLinkPermission(newVal, oldVal) {
    //   let view = this;
    //   console.log("SSS", newVal, oldVal);
    //   if (oldVal.length > 0) {
    //     Object.assign(view.permissionToUpdate, {
    //       module: newVal,
    //     });
    //   }
    // },
    country(newVal, oldVal) {
      let view = this;
      console.log("Country", newVal, oldVal);
      Object.assign(view.permissionToUpdate, {
        country: [newVal],
      });
    },
  },
  // computed: {
  //   _items() {
  //     const replaceChildren = (obj, parent) => {
  //       // console.log("West", obj, parent);
  //       const clone = Object.assign({}, obj);
  //       delete clone.children;
  //       if (parent) clone.parent = parent;
  //       return clone;
  //     };

  //     const addItems = (arr, parent) => {
  //       const items = arr.reduce((acc, x) => {
  //         acc.push(replaceChildren(x, parent));
  //         if (x.children) {
  //           acc.push(addItems(x.children, x.id));
  //         }
  //         return acc;
  //       }, []);

  //       return items.flat();
  //     };

  //     return addItems(this.sectorData).reduce((acc, x) => {
  //       acc[x.id] = x;
  //       return acc;
  //     }, {});
  //   },
  //   _selection() {
  //     let view = this;
  //     const proxy = {};
  //     function addParents(x, all) {
  //       const parentId = view._items[x.id].parent;
  //       if (parentId) {
  //         if (all) addParents(view._items[parentId]);
  //         proxy[parentId] = view._items[parentId];
  //       }
  //     }

  //     this.selectedSecor.forEach((x) => {
  //       console.log("XXX", x);
  //       if (x.children !== undefined) {
  //         addParents(x, view.allParentNodes);
  //         proxy[x.id] = x;
  //       }
  //     });
  //     // console.log("West.", proxy);
  //     return Object.values(proxy);
  //   },
  // },
  mounted() {
    let view = this;
    console.log("id", view.$route.params.id);
    view.getBasicData();
    view.getUserPermission();
    // view.getRawData();

    // view.getSectorApi();
    // view.get_sectors(0, "sector");
    // let manage_reports_url = view.$url("PANALISTUPLOAD");
  },
  computed: {
    userIdCookie: function () {
      return this.$utils.getCookies("user_id");
    },
  },
  methods: {
    getBasicData() {
      let view = this;
      let rawData = JSON.parse(localStorage.getItem("rawdata"));
      let sectorData = JSON.parse(localStorage.getItem("sector"));
      let clientRawData = JSON.parse(localStorage.getItem("clienRaw"));
      console.log("RAwwwww", rawData, sectorData);
      if (rawData !== undefined && rawData !== null) {
        view.mediaChannel = rawData.data.mediachannel;
        view.audience = rawData.data.audience;
        const indexstates = rawData.data.states;
        indexstates.sort((a, b) => a.stateID - b.stateID);
        console.log("Basic Detail", indexstates);
        view.stateProvinance = indexstates;
      }
      if (sectorData !== undefined && sectorData !== null) {
        view.sectorData = sectorData;
      }
      if (clientRawData !== undefined && clientRawData !== null) {
        view.additonalField = clientRawData.data.data.additional_fields;
        for (
          let i = 0;
          i < Object.keys(clientRawData.data.data.dashboard).length;
          i++
        ) {
          view.dashboardPermission.push({
            id: parseInt(Object.keys(clientRawData.data.data.dashboard)[i]),
            value: Object.values(clientRawData.data.data.dashboard)[i],
          });
        }
        console.log("Dashboard", view.dashboardPermission);
        view.exclude_search = clientRawData.data.data.exclude_search;
      }
    },
    validation() {
      let view = this;
      const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      console.log("view.texFieldState.password", view.texFieldState.password);
      if (!regex.test(view.texFieldState.emailAddress)) {
        view.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Please enter valid email address",
        });
        return false;
      } else if (
        view.texFieldState.password.length <= 15 &&
        !/^(?=.*[A-Z])(?=.*\d)(?=.*\W)/.test(view.texFieldState.password)
      ) {
        view.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Password policy: Min. 15 characters with at least one capital letter, a number and a special character(e.g:uv$yrde34U4RX5f>)",
        });
        return false;
      } else if (view.texFieldState.companyName.length === 0) {
        view.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Please enter Company Name",
        });
      } else if (view.texFieldState.number_machines < 0) {
        view.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Only Real number are allowed",
        });
        return false;
      } else if (view.selMediaChannel.length <= 0) {
        view.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Please select Media Channels",
        });
        return false;
      } else if (view.selAudience.length <= 0) {
        view.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Please select Audience",
        });
        return false;
      } else if (view.selectedSecor.length <= 0) {
        view.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Please select Area of Interest",
        });
        return false;
      } else {
        return true;
      }
    },
    save(e) {
      let view = this;
      console.log("WWSS", view.userIdCookie);
      let addValues = {
        bypass: e.bypass,
        city: e.city,
        clientName: e.clientName,
        companyName: e.companyName,
        country: e.country,
        emailAddress: e.emailAddress,
        fax: e.fax,
        firstName: e.firstName,
        lastName: e.lastName,
        number_machines: e.number_machines,
        phone: e.phone,
        state: e.state,
        streetAddress: e.streetAddress,
        zipCode: e.zipCode,
        modified_by: view.userIdCookie.toString(),
      };
      // view.validation();
      // console.log("SSs", view.validation());
      console.log("Save", addValues, view.permissionToUpdate);
      if (view.validation()) {
        let client_basic_url = view.$url("CLIENT_USER");
        let manage_reports_url = view.$url("USER_PERMISSION");
        view
          .$fetch({
            requiresAuth: true,
            operation: "view",
            vueScope: view,
          })
          .post(client_basic_url, [addValues])
          .then((result) => {
            console.log("REs", result.data.data.done_recs[0].userID, result);
            // setTimeout(2000);
            view
              .$fetch({
                requiresAuth: true,
                operation: "view",
                vueScope: view,
              })

              .post(manage_reports_url, view.permissionToUpdate, {
                headers: {
                  "user-id": result.data.data.done_recs[0].userID,
                },
              })
              .then((result) => {
                console.log("WWW", result);
                view.isLoading = false;
                view.$router.push("/manage-client-users");
              })
              .catch((error) => {
                view.isLoading = false;
                console.error(error);
              });
            // view.$router.push("/membermanagement");
          })
          .catch((error) => {
            view.isLoading = false;
            console.error(error);
          });
      }
    },
    extractAdditionalFieldName(e) {
      let field_name = e.toUpperCase().replaceAll("_", " ");
      return field_name;
    },
    saveAndAddMore() {
      let view = this;
      view.isLoading = true;
      let addValues = {
        bypass: view.texFieldState.bypass,
        city: view.texFieldState.city,
        clientName: view.texFieldState.clientName,
        companyName: view.texFieldState.companyName,
        country: view.texFieldState.country,
        emailAddress: view.texFieldState.emailAddress,
        fax: view.texFieldState.fax,
        firstName: view.texFieldState.firstName,
        lastName: view.texFieldState.lastName,
        number_machines: view.texFieldState.number_machines,
        phone: view.texFieldState.phone,
        state: view.texFieldState.state,
        streetAddress: view.texFieldState.streetAddress,
        zipCode: view.texFieldState.zipCode,
        modified_by: view.userIdCookie.toString(),
      };
      // view.validation();
      console.log("SSs", view.validation());
      console.log("Save", addValues, view.permissionToUpdate);
      if (view.validation()) {
        let client_basic_url = view.$url("CLIENT_USER");
        let manage_reports_url = view.$url("USER_PERMISSION");
        view
          .$fetch({
            requiresAuth: true,
            operation: "view",
            vueScope: view,
          })
          .post(client_basic_url, [addValues])
          .then((result) => {
            // view.texFieldState.bypass = result.data.data.done_recs[0].bypass;
            // view.texFieldState.city = result.data.data.done_recs[0].city;
            // view.texFieldState.clientName =
            //   result.data.data.done_recs[0].clientName;
            // view.texFieldState.companyName =
            //   result.data.data.done_recs[0].companyName;
            // view.texFieldState.country = result.data.data.done_recs[0].country;
            // view.texFieldState.emailAddress =
            //   result.data.data.done_recs[0].emailAddress;
            // view.texFieldState.fax = result.data.data.done_recs[0].fax;
            // view.texFieldState.firstName =
            //   result.data.data.done_recs[0].firstName;
            // view.texFieldState.lastName =
            //   result.data.data.done_recs[0].lastName;
            // view.texFieldState.number_machines =
            //   result.data.data.done_recs[0].number_machines;
            // view.texFieldState.phone = result.data.data.done_recs[0].phone;
            // view.texFieldState.state = result.data.data.done_recs[0].state;
            // view.texFieldState.streetAddress =
            //   result.data.data.done_recs[0].streetAddress;
            // view.texFieldState.zipCode = result.data.data.done_recs[0].zipCode;
            console.log("REs", result.data.data.done_recs[0].userID, result);
            // setTimeout(2000);
            view
              .$fetch({
                requiresAuth: true,
                operation: "view",
                vueScope: view,
              })

              .post(manage_reports_url, view.permissionToUpdate, {
                headers: {
                  "user-id": result.data.data.done_recs[0].userID,
                },
              })
              .then((res) => {
                console.log("WWW", res);
                view.isLoading = false;
                view.$router.push(
                  "/edit-member/" + result.data.data.done_recs[0].userID
                );
              })
              .catch((error) => {
                view.isLoading = false;
                console.error(error);
              });
            // view.$router.push("/membermanagement");
          })
          .catch((error) => {
            view.isLoading = false;
            console.error(error);
          });
      }
    },
    getUserPermission() {
      let view = this;
      if (
        view.$route.params.id !== undefined &&
        view.$route.params.id !== null
      ) {
        view.isLoading = true;
        view.isEdit = true;
        // view.selectionType = "independent";
        let client_basic_url = view.$url("CLIENT_USER");
        view
          .$fetch({
            requiresAuth: true,
            operation: "view",
            vueScope: view,
          })
          .get(client_basic_url, {
            headers: {
              "user-id": parseInt(view.$route.params.id),
            },
          })
          .then((res) => {
            view.texFieldState = res.data;
            view.userId = res.data.userID;
            view.texFieldState.state = parseInt(res.data.state);
            console.log("Client", view.texFieldState);
            if (res.data.bypass === 1) {
              view.byPassSecurity = true;
            } else {
              view.byPassSecurity = false;
            }
          });
        let manage_reports_url = view.$url("USER_PERMISSION");

        console.log("Url", manage_reports_url);
        this.$fetch({
          requiresAuth: true,
          operation: "view",
          vueScope: view,
        })
          .get(manage_reports_url, {
            headers: {
              "user-id": parseInt(view.$route.params.id),
            },
          })
          .then((response) => {
            console.log("Res", response);
            // view.selectedSecor = [];
            view.selMediaChannel = response.data.mediachannel;
            view.selAudience = response.data.audience;
            view.setPagePermission = response.data.module;
            view.setAdditionalFieldsForPowerSearch = response.data.additional;
            view.selDashboardPermission = response.data.edc_user;
            view.setExcludeFromPowerSearch = response.data.additional_exclude;
            view.country = response.data.country[0];
            for (let i = 0; i < response.data.sector.length; i++) {
              view.selectedSecor.push({
                id: response.data.sector[i],
              });
            }
            console.log("seltedSector", view.selectedSecor);
            view.isLoading = false;
            // view.$swal.fire({
            //   icon: "success",
            //   title: "Success",
            //   text: response.data.message,
            // });
            // view.reportFile = null;
            // view.getTempData(view.page);
          })
          .catch((error) => {
            console.error(error);
            view.isLoading = false;
          });
      } else {
        view.isEdit = false;
      }
    },
    editData() {
      let view = this;
      console.log("permissionToUpdate", view.permissionToUpdate);

      if (view.validation()) {
        view.isLoading = true;
        if (view.byPassSecurity) {
          view.texFieldState.bypass = 1;
        } else {
          view.texFieldState.bypass = 0;
        }
        view.texFieldState.modified_by = view.userIdCookie;
        delete view.texFieldState.modified_date;
        let editData = Object.assign({
          [view.userId.toString()]: {
            ...view.texFieldState,
          },
        });
        console.log("Edit", editData);

        let client_basic_url = view.$url("CLIENT_USER");
        let manage_reports_url = view.$url("USER_PERMISSION");
        view
          .$fetch({
            requiresAuth: true,
            operation: "view",
            vueScope: view,
          })
          .patch(client_basic_url, editData)
          .then((result) => {
            view
              .$fetch({
                requiresAuth: true,
                operation: "view",
                vueScope: view,
              })
              .post(manage_reports_url, view.permissionToUpdate, {
                headers: {
                  "user-id": view.userId,
                },
              })
              .then((result) => {
                console.log("WWW", result);
                view.isLoading = false;
                view.$router.push("/manage-client-users");
              })
              .catch((error) => {
                view.isLoading = false;
                console.error(error);
              });
            // view.$router.push("/membermanagement");
          })
          .catch((error) => {
            view.isLoading = false;
            console.error(error);
          });
      }
    },
    checkIfOperationExistForModule(type) {
      return this.$utils.checkIfOperationExistForModule(this, type);
    },
  },
};
</script>
<style scoped>
.label-margin {
  margin-top: 2% !important;
}

.v-chip--select .v-chip .v-chip--clickable {
  margin: 4px, 0px, 4px, 0px;
  background-color: red;
}
.t-margin-btm {
  margin-bottom: -30px;
}
.theme--light.v-treeview {
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 14px !important;
  font-weight: bold !important;
}
.anchor-tag {
  color: red;
}
.anchor-tag-hov {
  color: red;
  text-decoration: underline;
}
</style>
<style lang="sass" scoped>
@import './../../sass/product/product'
.v-card__title
  font-size: 18px!important
.theme--light.v-data-table.v-data-table--fixed-header thead th
  background: #3f51b5 !important
</style>
