<template>
  <v-container id="alerts" fluid tag="section">
    <!-- <v-dialog v-model="toggleAddEdit" max-width="600">
      <template>
        <v-card>
          <v-form ref="addReportForm" lazy-validation>
            <v-card-title>
              <b style="font-size: 22px !important">Import Panelist Score</b>
            </v-card-title>
            <v-card-text>
              <MemberManagementAddEditMember />
            </v-card-text>
          </v-form>
        </v-card>
      </template>
    </v-dialog> -->
    <div class="text-right">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
    </div>
    <v-dialog v-model="toggleFileUploadModel" max-width="600">
      <template>
        <v-card>
          <v-form ref="addReportForm" lazy-validation>
            <v-card-title>
              <b style="font-size: 22px !important">Import Panelist Score</b>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row
                  justify="center"
                  style="text-align: center; font-size: 14px"
                >
                  <b style="line-height: 31px"
                    >Upload CSV with one entry per row and fields in order:</b
                  >
                  Email Address, Password, First Name, Last Name, Company Name,
                  Client Name, Street Address, City, State/Province, Division,
                  Zip Code, Phone, Fax
                </v-row>
                <v-row justify="center">
                  <v-col cols="12" sm="12" md="12">
                    <v-file-input
                      label="File input*"
                      accept=".csv"
                      outlined
                      dense
                      v-model="reportFile"
                    >
                    </v-file-input>
                  </v-col>
                </v-row>
                <v-row style="padding-left: 6%; margin-top: -42px">
                  <v-col>
                    <small>*indicates required field</small>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" class="mr-4" @click="addReport">
                Upload
              </v-btn>
              <v-btn color="default" @click="toggleFileUploadModel = false">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </template>
    </v-dialog>
    <v-row justify="center">
      <v-dialog v-model="errorModel" max-width="760" persistent>
        <template>
          <v-card>
            <v-form ref="addReportForm" lazy-validation>
              <v-card-title>
                <b style="font-size: 22px !important"
                  >Client-User Import Error</b
                >
              </v-card-title>
              <v-card-text>
                <v-container style="max-height: 570px; overflow-y: scroll">
                  <v-col cols="12">
                    <v-data-table
                      :headers="errorHeaders"
                      item-key="emailAddress"
                      :items="errorList"
                      :loading="loading"
                      class="elevation-1"
                      hide-default-footer
                      disable-pagination
                    >
                    </v-data-table>
                  </v-col>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeErrorModal">
                  Close
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </template>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-select
          v-model="filterName"
          :items="filterItems"
          menu-props="auto"
          label="Select Search By"
          hide-details
          prepend-icon="mdi-account"
          single-line
          @change="resetfilterValue"
        ></v-select>
      </v-col>
      <v-col cols="5">
        <div v-if="filterName === 'Member Email'">
          <v-text-field
            label="Search by email"
            v-model="emailAddress"
            @keydown.enter="search"
          />
        </div>
        <div v-if="filterName === 'Company Name'">
          <v-text-field
            label="Search by company"
            v-model="companyName"
            @keydown.enter="search"
          />
        </div>
        <div v-if="filterName === 'Active'">
          <v-select
            v-model="activeModle"
            :items="activeOPtions"
            menu-props="auto"
            label="Select Search By"
            hide-details
            single-line
            item-text="name"
            item-value="value"
          ></v-select>
        </div>
        <div v-if="filterName === 'Cognito Account Status'">
          <v-select
            v-model="accStatus"
            :items="status"
            menu-props="auto"
            hide-details
            single-line
          ></v-select>
        </div>
      </v-col>
      <v-col cols="4">
        <v-btn small color="primary" class="mt-5" @click="search">
          Search
        </v-btn>
        <v-btn small color="default" class="mt-5" @click="reset"> Reset </v-btn>
      </v-col>
      <v-col cols="12">
        <div style="float: right">
          <v-btn
            small
            color="primary"
            class="mt-5"
            @click="toggleFileUploadModel = true"
            v-show="checkIfOperationExistForModule('import')"
          >
            Import
          </v-btn>
          <v-btn
            small
            color="primary"
            class="mt-5"
            v-show="checkIfOperationExistForModule('export')"
            @click="exportClientUser"
          >
            Export
          </v-btn>

          <v-btn
            small
            color="primary"
            class="mt-5"
            @click="addMember()"
            v-show="checkIfOperationExistForModule('add')"
          >
            Add Member
          </v-btn>
          <v-btn
            small
            color="primary"
            class="mt-5"
            @click="beforeActivateAndBlock"
            v-show="checkIfOperationExistForModule('edit')"
          >
            Activate/Block
          </v-btn>
          <v-btn
            small
            color="error"
            class="mt-5"
            @click="deleteClient"
            v-show="checkIfOperationExistForModule('delete')"
          >
            Delete
          </v-btn>
        </div>
      </v-col>

      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :single-select="singleSelect"
          v-model="selected"
          :items="items"
          :loading="isLoading"
          :items-per-page="perpage"
          :options.sync="options"
          :sortBy.sync="sortby"
          :sort-desc.sync="sortDesc"
          show-select
          item-key="userID"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:item.emailAddress="{ item }">
            <v-hover v-slot:default="{ hover }">
              <a
                @click="sendEmail(item)"
                :class="hover ? 'anchor-tag-hov' : ''"
                >{{ item.emailAddress }}</a
              >
            </v-hover>
          </template>
          <template v-slot:item.active="{ item }">
            <span>{{ item.active === 1 ? "Yes" : "No" }}</span>
          </template>
          <!-- <template v-slot:item.created_date="{ item }">
            <span>{{ item.insert_update_date.slice(0, 10) }}</span>
          </template>
          <template v-slot:item.modified_date="{ item }">
            <span>{{ item.insert_update_date.slice(0, 10) }}</span>
          </template> -->
          <template v-slot:item.actions="{ item }">
            <v-icon
              medium
              color="grey"
              @click="updateMember($event, item)"
              v-show="checkIfOperationExistForModule('edit')"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
        <div class="text-center pt-4 pb-4">
          <Pagination
            :isloading="isLoading"
            :startRecord="startRecord"
            :currentPage="currentPage"
            :lastPage="lastPage"
            :lastRecord="lastRecord"
            :totRecords="totRecords"
            :isCurrentPageClass="isCurrentPageClass"
            :getLastPageClass="getLastPageClass"
            :totPage="totPage"
            :perpage="perpage"
            :getPages="getPages"
            @handlePerPage="handlePerPage"
            @paginate="paginate"
            @last="last"
            @getDataByPage="getDataByPage"
            :showPerPage="showPerPage"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import MemberManagementAddEditMember from "./MemberManagementAddEditMember.vue";
import Pagination from "./component/Pagination.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "MemberManagement",
  components: {
    MemberManagementAddEditMember,
    Loading,
    Pagination,
  },
  data: () => ({
    loading: false,
    singleSelect: false,
    headers: [
      {
        text: "Member Email",
        value: "emailAddress",
      },
      { text: "Company Name", value: "companyName" },
      { text: "Cognito Account Status", value: "cognito_account_status" },
      { text: "Active", value: "active" },
      { text: "Created Date", value: "created_date" },
      { text: "Modified Date", value: "modified_date" },
      { text: "Last Updated By", value: "modified_by" },
      { text: "Edit Member", value: "actions", sortable: false },
    ],
    items: [],
    toggleAddEdit: false,
    toggleFileUploadModel: false,
    reportFile: null,
    selected: [],
    isLoading: false,
    fullPage: true,
    showPerPage: true,
    page: 1,
    emailAddress: "",
    companyName: "",
    filterItems: [
      "Member Email",
      "Company Name",
      "Active",
      "Cognito Account Status",
    ],
    filterName: "Member Email",
    activeModle: 0,
    activeOPtions: [
      {
        value: 0,
        name: "Inactive",
      },
      {
        value: 1,
        name: "Active",
      },
    ],
    options: {},
    sortby: "companyName",
    sortDesc: false,
    sort: "asc",
    status: ["CONFIRMED", "FORCE_CHANGE_PASSWORD", "RESET_REQUIRED"],
    accStatus: "",
    errorHeaders: [
      {
        text: "Email",
        value: "emailAddress",
      },
      { text: "Row Number", value: "rowNum" },
      { text: "Error", value: "message" },
    ],
    errorList: [],
    errorModel: false,
    // perpage: 50,
  }),
  async beforeMount() {
    await this.getRawData();
    await this.getSectorApi();
    await this.getClientRawData();
  },
  async mounted() {
    await this.getuserClients();
  },
  watch: {
    selected: function (o) {
      console.log("WWWw", o);
    },
    options: function (o) {
      let sortby = o.sortBy[0];
      let direction = o.sortDesc[0];
      if (!this.reset_flag) {
        if (sortby != undefined && direction != undefined) {
          this.sortby = sortby;
          if (o.page === 1) {
            this.page = 1;
          }
          if (direction) {
            this.sort = "desc";
          } else {
            this.sort = "asc";
          }
          this.getuserClients();
        }
      } else {
        this.reset_flag = false;
      }
    },
  },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    perpage: function () {
      return this.$store.getters["MemberManagement/getPerPage"];
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    totRecords: function () {
      var store = this.$store;
      return store.getters["MemberManagement/getTotal"] == ""
        ? 0
        : store.getters["MemberManagement/getTotal"];
    },
    currentPage: function () {
      return this.$store.getters["MemberManagement/getCurrentPage"];
    },
    lastPage: function () {
      return this.$store.getters["MemberManagement/getLastPage"];
    },
    ///////
    // total_count: function () {
    //         return this.dedup.duplicates.split(',').length
    //     },
    dmduplicates: function () {
      return this.allDmduplicates();
    },
    searchQuery: {
      get: function () {
        return this.$store.getters["MemberManagement/getSearchQuery"];
      },
      set: function (o) {
        this.$store.dispatch("MemberManagement/setSearchQuery", o);
      },
    },
    sesrchById: {
      get: function () {
        return this.$store.getters["MemberManagement/getSearchById"];
      },
      set: function (o) {
        this.$store.dispatch("MemberManagement/setSetSearchBySubject", false);
        this.$store.dispatch("MemberManagement/setSearchQuery", "");
        this.$store.dispatch("MemberManagement/setSearchById", o);
      },
    },
    searchBySubject: {
      get: function () {
        return this.$store.getters["MemberManagement/setSetSearchBySubject"];
      },
      set: function (o) {
        this.$store.dispatch("MemberManagement/setSearchById", false);
        this.$store.dispatch("MemberManagement/setSearchQuery", "");
        this.$store.dispatch("MemberManagement/setSetSearchBySubject", o);
      },
    },
  },
  methods: {
    getRawData() {
      let view = this;
      let rawdata = view.$url("ALL_RAWDATA");
      // let rawdata = "https://api7.competiscan.com/product/v1/data/rawdata";
      this.$fetch({
        requiresAuth: true,
        operation: "view",
        vueScope: view,
      })
        .get(rawdata)
        .then(async (response) => {
          console.log("RawData", rawdata);
          await localStorage.setItem("rawdata", JSON.stringify(response));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getClientRawData() {
      let view = this;
      let rawdata = view.$url("CLIENT_RAWDATA");
      this.$fetch({
        requiresAuth: true,
        operation: "view",
        vueScope: view,
      })
        .get(rawdata)
        .then(async (response) => {
          await localStorage.setItem("clienRaw", JSON.stringify(response));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getSectorApi() {
      let view = this;
      view.isLoading = true;
      console.log("Sector", JSON.parse(localStorage.getItem("sector")));
      if (JSON.parse(localStorage.getItem("sector")) === null) {
        let sector_api = view.$url("ALLSECTORS") + "?type=sector";
        this.$fetch({
          requiresAuth: true,
          operation: "view",
          vueScope: view,
        })
          .get(sector_api)
          .then(async (response) => {
            console.log("Sector.", response);
            await localStorage.setItem(
              "sector",
              JSON.stringify(response.data.payload)
            );
            view.isLoading = false;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    updateMember(e, items) {
      console.log("TRE", e, "WWWw", items);
      let view = this;
      view.$store.dispatch("MemberManagement/isEdit", true);
      localStorage.setItem("client-basic", JSON.stringify(items));
      // view.toggleAddEdit = true;
      view.$router.push("/edit-member/" + items.userID);
    },
    addMember() {
      // console.log("TRE", e, "WWWw", atchid);
      let view = this;
      view.$store.dispatch("MemberManagement/isEdit", false);
      view.$router.push("/add-member");
      // view.toggleAddEdit = true;
    },
    addReport() {
      let view = this;
      view.errorList = [];
      if (view.$refs.addReportForm.validate()) {
        let formData = new FormData();
        formData.append("file", this.reportFile);
        console.log("llll", formData);
        let manage_reports_url = view.$url("CLIENT_IMPORT");
        if (this.reportFile !== null) {
          if (this.reportFile.type === "text/csv") {
            view.isLoading = true;
            this.$fetch({
              requiresAuth: true,
              operation: "add",
              vueScope: view,
            })
              .post(manage_reports_url, formData, {
                headers: {
                  // 'Authorization': accestoken,
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((response) => {
                console.log("Import", response);
                view.isLoading = false;
                view.toggleFileUploadModel = false;
                view.errorList = response.data.data;
                if (response.data.data.length > 0) {
                  view.$swal
                    .fire({
                      icon: "success",
                      title: "Success",
                      text: "Success but some fields are not saved",
                      showCancelButton: true,
                      confirmButtonText: "Show error list",
                      cancelButtonText: "Ok",
                      showCloseButton: true,
                      showLoaderOnConfirm: true,
                      allowOutsideClick: false,
                    })
                    .then((result) => {
                      if (result.value) {
                        view.errorModel = true;
                      } else {
                        view.getuserClients();
                      }
                    });
                } else {
                  view.$swal.fire(
                    {
                      icon: "success",
                      title: "Success",
                      text: response.data.msg,
                    },
                    function () {
                      view.getuserClients();
                    }
                  );
                }

                // view.reportFile = null;
                // view.getTempData(view.page);
              })
              .catch((error) => {
                console.error(error);
                // console.error(error.response.data.message, "ERrr");
                view.isLoading = false;
                // view.reportFile = null;
                view.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
              });
          } else {
            view.isLoading = false;
            view.$swal.fire({
              icon: "error",
              title: "Error",
              text: "Only csv format allowed!",
            });
          }
        }
      }
    },
    getuserClients() {
      let view = this;
      view.isLoading = true;
      let opt = {};

      if (view.companyName.length) {
        Object.assign(opt, {
          companyName: view.companyName,
        });
      } else if (
        view.activeModle !== undefined &&
        view.filterName === "Active"
      ) {
        Object.assign(opt, {
          active: view.activeModle,
        });
      } else if (view.accStatus.length) {
        Object.assign(opt, {
          cognito_account_status: view.accStatus,
        });
      } else {
        Object.assign(opt, {
          emailAddress: view.emailAddress,
        });
      }
      // view.get_sectors(0, "sector");
      // let manage_reports_url = view.$url("PANALISTUPLOAD");
      let manage_reports_url = view.$url("ALL_CLIENT_USER");

      console.log("Url", view.sortby, view.sort);
      view.$store
        .dispatch("MemberManagement/setIsFromSearch", true)
        .then(function () {
          return view.$store.dispatch("MemberManagement/setIsLoading", true);
        })
        .then(function () {
          return view.$store.dispatch(
            "MemberManagement/setResponsePayload",
            []
          );
        });
      this.$fetch({
        requiresAuth: true,
        operation: "view",
        vueScope: view,
      })
        .post(manage_reports_url, {
          page:
            view.$store.getters["MemberManagement/getRequestPayload"].page - 1,
          perpage:
            view.$store.getters["MemberManagement/getRequestPayload"].perpage,
          options: opt,
          sort: { [view.sortby]: view.sort },
        })
        .then(async (response) => {
          console.log("Res", response);
          view.items = await response.data.data;

          // view.$store.dispatch("MemberManagement/setEndTime", new Date().getTime());
          view.$store.dispatch("MemberManagement/setIsLoading", false);
          typeof view.items !== "undefined"
            ? view.$store.dispatch(
                "MemberManagement/setResponsePayload",
                view.items
              )
            : [];
          // typeof response.hasNext !== "undefined"
          //   ? view.$store.dispatch(
          //       "MemberManagement/setHasNext",
          //       response.data.data
          //     )
          //   : "";
          //Total items
          typeof response.data.total_records !== "undefined"
            ? view.$store.dispatch(
                "MemberManagement/setTotal",
                response.data.total_records
              )
            : "";
          //Total pages
          typeof response.data.total_pages !== "undefined"
            ? view.$store.dispatch(
                "MemberManagement/setLastPage",
                response.data.total_pages
              )
            : "";
          if (JSON.parse(localStorage.getItem("sector")) !== null) {
            view.isLoading = false;
          }
          view.selected = [];
        })
        .catch((error) => {
          if (JSON.parse(localStorage.getItem("sector")) !== null) {
            view.isLoading = false;
          }
          console.error(error);
        });
    },
    dedupClass: function (i) {
      return parseInt(i) % 2 === parseInt(0)
        ? "grey lighten-2"
        : "grey lighten-5";
    },
    search: function () {
      if (
        this.companyName.length > 0 ||
        this.emailAddress.length > 0 ||
        this.accStatus.length > 0 ||
        this.activeModle !== undefined
      ) {
        this.$store.dispatch("MemberManagement/setPerPage", 50);
        this.$store.dispatch("MemberManagement/setPage", parseInt(1));
        this.getuserClients();
      }
    },
    clearSearch: function () {
      var view = this;
      view.$store
        .dispatch("MemberManagement/setIsFromSearch", false)
        .then(function () {
          return view.$store.dispatch("MemberManagement/setSearchById", true);
        })
        .then(function () {
          return view.$store.dispatch(
            "MemberManagement/setSetSearchBySubject",
            false
          );
        })
        .then(function () {
          return view.$store.dispatch(
            "MemberManagement/setPerPage",
            view.perpage
          );
        })
        .then(function () {
          return view.$store.dispatch("MemberManagement/setPage", 1);
        })
        .then(function () {
          view.getuserClients();
        });
    },
    checkIfOperationExistForModule(type) {
      return this.$utils.checkIfOperationExistForModule(this, type);
    },
    //pagination methods
    getDataByPage(page) {
      let view = this;
      console.log("Data", page);
      view.$store
        .dispatch("MemberManagement/setPage", page)
        .then(function () {
          return view.$store.dispatch("MemberManagement/setIsLoading", true);
        })
        .then(function () {
          view.getuserClients();
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    handlePerPage(p) {
      let view = this;
      view.$store
        .dispatch("MemberManagement/setPerPage", p)
        .then(function () {
          return view.$store.dispatch("MemberManagement/setPage", parseInt(1));
        })
        .then(function () {
          return view.$store.dispatch("MemberManagement/setIsLoading", true);
        })
        .then(function () {
          view.getuserClients();
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    paginate(n) {
      console.log(n);
      let view = this;
      if (
        parseInt(n) === parseInt(0) &&
        parseInt(this.currentPage) === parseInt(1)
      ) {
        return;
      }
      if (parseInt(n) === parseInt(-1)) {
        if (parseInt(this.currentPage) <= parseInt(1)) {
          return;
        }
      }
      if (parseInt(n) === parseInt(1)) {
        if (parseInt(this.currentPage) >= parseInt(this.lastPage)) {
          return;
        }
      }
      let myPromise = new Promise(function (resolve, reject) {
        resolve(
          view.$store.dispatch(
            "MemberManagement/setPage",
            n === 0 ? 1 : parseInt(view.currentPage) + parseInt(n)
          )
        );
        reject("Error: on set page");
      });
      myPromise
        .then(function () {
          view.$store.getters["MemberManagement/getIsFromSearch"]
            ? view.getuserClients()
            : view.getuserClients();
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    last() {
      let view = this;
      console.log("last", view.lastPage);
      if (parseInt(view.lastPage) === parseInt(view.currentPage)) {
        return;
      }
      view.$store
        .dispatch("MemberManagement/setPage", view.lastPage)
        .then(function () {
          view.$store.dispatch("MemberManagement/setIsLoading", true);
        })
        .then(function () {
          view.getuserClients();
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    sendEmail(e) {
      location.href = "mailto:" + e.emailAddress;
    },
    beforeActivateAndBlock() {
      let view = this;
      if (view.selected.length > 0) {
        view.$swal
          .fire({
            title: "Member update",
            text: "Are you sure you want to Activate/Block?",
            showCancelButton: true,
            confirmButtonText: "Ok",
            cancelButtonText: "Cancel",
            closeOnConfirm: false,
            closeOnCancel: false,
            allowOutsideClick: false,
            showLoaderOnConfirm: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              view.activateBlockClint();
            } else {
              console.log("Do noting");
            }
          });
      } else {
        view.$swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "Please select member",
        });
      }
    },
    activateBlockClint() {
      let view = this;
      view.isLoading = true;
      let activateBlock = {};
      for (let i = 0; i < view.selected.length; i++) {
        if (view.selected[i].active === 1) {
          Object.assign(activateBlock, {
            [view.selected[i].userID]: { active: 0 },
          });
        } else {
          Object.assign(activateBlock, {
            [view.selected[i].userID]: { active: 1 },
          });
        }
      }
      let client_basic_url = view.$url("CLIENT_USER");
      view
        .$fetch({
          requiresAuth: true,
          operation: "view",
          vueScope: view,
        })
        .patch(client_basic_url, activateBlock)
        .then((result) => {
          this.$swal.fire({
            icon: "success",
            title: "Member update",
            text: `${view.selected.length} ${
              view.selected.length <= 1 ? "member" : "members"
            } Activated/Blocked.`,
          });
          view.getuserClients();
          view.selected = [];
          view.isLoading = false;
        })
        .catch((error) => {
          view.isLoading = false;
          console.error(error);
        });

      console.log("ass", activateBlock);
    },
    deleteClient() {
      let view = this;
      if (view.selected.length > 0) {
        // view.isLoading = true;
        view.$swal
          .fire({
            title: "Are you sure ?",
            text: "This will remove member",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, remove it !",
            cancelButtonText: "No, cancel !",
            closeOnConfirm: false,
            closeOnCancel: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              let deleteClient = [];
              for (let i = 0; i < view.selected.length; i++) {
                deleteClient.push(view.selected[i].userID);
              }
              let client_basic_url = view.$url("CLIENT_USER");
              view
                .$fetch({
                  requiresAuth: true,
                  operation: "view",
                  vueScope: view,
                })
                .delete(client_basic_url, {
                  headers: {
                    "user-id": deleteClient.toString(),
                  },
                })
                .then((result) => {
                  this.$swal.fire({
                    icon: "success",
                    title: "Memeber update",
                    text: "Member has been delted successfully",
                  });
                  view.getuserClients();
                  view.selected = [];
                  view.isLoading = false;
                })
                .catch((error) => {
                  view.isLoading = false;
                  console.error(error);
                });
            } else {
              view.selected = [];
            }
          });
      } else {
        view.$swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "Please select member",
        });
      }
    },
    exportClientUser() {
      let view = this;
      view.isLoading = true;
      let export_client_report = view.$url("EXPORT_CLIENT_REPORT");
      console.log(
        "Total value",
        view.$store.getters["MemberManagement/getTotal"]
      );
      this.$fetch({
        requiresAuth: true,
        operation: "view",
        vueScope: view,
      })
        .post(export_client_report, {
          page:
            view.$store.getters["MemberManagement/getRequestPayload"].page - 1,
          perpage: view.$store.getters["MemberManagement/getTotal"] - 1,
        })
        .then(async (response) => {
          console.log("Export Response", response);
          view.isLoading = false;
          const anchor = document.createElement("a");
          anchor.href =
            "data:text/csv;charset=utf-8," + encodeURIComponent(response.data);
          anchor.target = "_blank";
          let date = new Date();
          let month = date.getMonth() + 1;
          let strdate =
            date.getFullYear().toString() +
            month.toString() +
            date.getDate().toString();
          console.log("Date Transform", strdate, "ss", date.getMonth());
          anchor.download = `members_${strdate}.csv`;
          anchor.click();
        })
        .catch((error) => {
          view.isLoading = false;
          console.error(error);
        });
    },
    reset() {
      var view = this;
      view.filterName = "Member Email";
      view.companyName = "";
      view.emailAddress = "";
      view.accStatus = "";
      view.activeModle = 0;
      view.$store.dispatch("MemberManagement/setPerPage", 50);
      view.$store.dispatch("MemberManagement/setPage", parseInt(1));
      view.$store.dispatch("MemberManagement/setResponsePayload", []);
      view.$store.dispatch("MemberManagement/setTotal", "");
      view.$store.dispatch("MemberManagement/setLastPage", 0);
      view.sortby = "companyName";
      view.sortDesc = false;
      view.getuserClients();
    },
    textSearch(e) {
      console.log("SSSs", e);
    },
    resetfilterValue() {
      this.emailAddress = "";
      this.companyName = "";
      this.accStatus = "";
      this.activeModle = 0;
    },
    closeErrorModal() {
      let view = this;
      view.errorModel = false;
      view.getuserClients();
    }
  },
};
</script>
<style scoped>
.anchor-tag-hov {
  color: black;
  text-decoration: underline;
}
</style>
